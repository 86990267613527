import { Login } from "@/views/login";
import { NavigationGuardWithThis, RouteLocationNormalized } from "vue-router";
import { useLoginStore } from "@/store/login";
import "nprogress/nprogress.css";
import Nprogress from "nprogress";

Nprogress.configure({ showSpinner: false });

const isNeedLogin = (to: RouteLocationNormalized) => {
  if (to?.meta?.login) return true;

  return false;
};

/**
 * 名牌分享跳转
 */
const cardSharePrase = (to: RouteLocationNormalized) => {
  if (!to) return "";

  // 解析只在首页进行，其他页面 不解析
  if (to.path !== "/") return "";

  const { query } = to;

  const { r: resumeId } = query;

  console.log("resume id => ", resumeId);

  // http://localhost:2022/?r=63f71600e6bc7f7042be2078
  if (resumeId) {
    return `/resume/${resumeId}`;
  }

  return "";
};

/**
 * 已经登陆过
 *
 * 但是重启应用，需要验证身份信息
 */
let init = false;

type NavType = NavigationGuardWithThis<undefined>;

export const navigationGuards: NavType = async (to, from) => {
  // console.log(to, from);

  const toResume = cardSharePrase(to);
  if (toResume) {
    return toResume;
  }

  if (!init) {
    Nprogress.start();
    try {
      const store = useLoginStore();
      await store.toVerify();
    } catch (error) {
      console.error(error);
    } finally {
      init = true;
    }
    Nprogress.done();
  }

  if (isNeedLogin(to)) {
    const store = useLoginStore();
    if (!store.isAuthenticated) {
      Nprogress.start();
      try {
        await Login.toLogin();
      } catch (error) {
        // 哪里来的回哪里去
        return from;
      } finally {
        Nprogress.done();
      }
    }
  }

  return true;
};
