import { QToast } from "@/components/q/QToast";
import { isFunc } from "@ff/utils";
import { computed, reactive, StyleValue } from "vue";

interface IState {
  time: number;
}

const config = {
  countdown: 10,
};
export class SendSortMessage {
  state: IState;
  constructor() {
    this.state = reactive({
      time: 0,
    });
  }

  subsub = () => {
    const { time } = this.state;
    setTimeout(() => {
      const newVal = time - 1;

      if (newVal > 0) {
        this.state.time = newVal;
        this.subsub();
      } else {
        this.state.time = 0;
      }
    }, 1000);
  };

  isSending = computed<boolean>(() => !!this.state.time);

  sendSmsHint = computed(() => {
    const { time } = this.state;
    if (time === 0 || time < 0) return "发送验证码";

    return `${time}`;
  });

  sendSmsStyle = computed(() => {
    const style: StyleValue = {
      "font-size": "0.8em",
      cursor: "pointer",
      color: "#5e8ff7",
    };

    if (this.isSending.value) {
      style.cursor = "not-allowed";
      style.color = "#d9d9d9";
    }

    return style;
  });

  onClickSend = async (func?: (time: number) => any) => {
    const isSending = this.isSending;
    if (isSending.value) return;

    if (func && isFunc(func)) {
      try {
        await func(this.state.time);
      } catch (error) {
        return QToast.error((error as Error)?.message || "Code Error");
      }
    }

    this.state.time = config.countdown;
    this.subsub();
  };
}
