import axios, {
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from "axios";
import { IHttpInterceptors } from "./type";

// 官网 https://axios-http.com/zh/docs/intro
// axios中文文档 http://axios-js.com/zh-cn/docs/index.html

const requestFulfilled = (config: InternalAxiosRequestConfig) => config;

const requestRejected = (error: any) => Promise.reject(error);

/**
 * 这里默认返回的是 res.data !!!
 */
const responseFulfilled = (res: AxiosResponse) => res?.data ?? res;

/**
 * 超出 2xx 范围的状态码都会触发该函数。
 */
const responseRejected = (error: AxiosError<any>): any => Promise.reject(error);

const getInstance = (initConfig?: CreateAxiosDefaults & IHttpInterceptors) => {
  // 详细配置 https://axios-http.com/zh/docs/req_config
  const instance = axios.create(initConfig);

  // 请求拦截器
  const request = {
    success: initConfig?.request?.success || requestFulfilled,
    error: initConfig?.request?.error || requestRejected,
  };
  instance.interceptors.request.use(request.success, request.error);

  // 响应拦截器
  const response = {
    success: initConfig?.response?.success || responseFulfilled,
    error: initConfig?.response?.error || responseRejected,
  };
  instance.interceptors.response.use(response.success, response.error);

  return instance;
};

/**
 * data?: D;
 */
export type configPlus<D = any> = AxiosRequestConfig<D> & {
  interceptors?: IHttpInterceptors;
};

/**
 * interceptors.response.success 默认返回 data，复写需要自己进行处理！
 */
export const get = <T = any>(
  apiPath: string,
  data?: object,
  config?: configPlus<any>
): Promise<T> => {
  const { interceptors, ...reqConfig } = config || {};

  const client = getInstance(interceptors);

  return client.get(apiPath, {
    params: data,
    ...reqConfig,
  });
};

/**
 * interceptors.response.success 默认返回 data，复写需要自己进行处理！
 */
export const post = <T = any>(
  apiPath: string,
  data?: object,
  config?: configPlus<any>
): Promise<T> => {
  const { interceptors, ...reqConfig } = config || {};

  const client = getInstance(interceptors);

  return client.post(apiPath, data, {
    ...reqConfig,
  });
};

export const initHttpClient = (init: configPlus<any>) => {
  const _get = <T = any>(
    apiPath: string,
    data?: object,
    config?: configPlus<any>
  ) => {
    return get<T>(apiPath, data, { ...init, ...config });
  };

  const _post = <T = any>(
    apiPath: string,
    data?: object,
    config?: configPlus<any>
  ) => {
    return post<T>(apiPath, data, { ...init, ...config });
  };
  return { get: _get, post: _post };
};
