import { defineStore } from "pinia";
import { reactive, computed } from "vue";
import { apiLogin, apiVerify } from "@/api/auth";
import { QToast } from "@/components/q/QToast";
import { getToken, saveToken } from "@/api/h";

interface IState {
  type: "未登陆" | "登陆中" | "登陆成功" | "登陆失败";
  token?: string;
}

const reqLogin = async (phone: string, code: string) => {
  const res = await apiLogin({
    phone,
    sms: code,
    type: "sms",
  });

  if (res.success && res.data) {
    QToast.ok(res.message || "登陆成功");
    saveToken(res.data);
    return true;
  }

  return false;
};

export const useLoginStore = defineStore("login", () => {
  const state = reactive<IState>({
    type: "未登陆",
    token: "",
  });

  const isAuthenticated = computed(() => state.type === "登陆成功");

  const isLanding = computed(() => state.type === "登陆中");

  const toLogin = async (phone: string, code: string) => {
    if (isLanding?.value) return;

    state.type = "登陆中";

    try {
      const apiRes = await reqLogin(phone, code);
      if (!apiRes) throw new Error("登陆失败");

      state.type = "登陆成功";
    } catch (error) {
      state.type = "登陆失败";
      throw error;
    }
  };

  const toVerify = async () => {
    if (isAuthenticated.value) return;
    if (state.type === "登陆失败") return;
    const token = getToken();
    if (!token) return;

    state.type = "登陆中";

    try {
      const res = await apiVerify();
      if (res.success && res.data) {
        state.type = "登陆成功";
        saveToken(res.data);

        return;
      }

      throw new Error("自动身份验证失败");
    } catch (error) {
      console.log(error);
      state.type = "登陆失败";
    }
  };

  return { isAuthenticated, isLanding, toLogin, toVerify };
});
