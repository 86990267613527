import { isFunc } from "@ff/utils";
import { h, render, VNode } from "vue";
import LoginPopupVue from "./popup.vue";

const remove = (container: HTMLDivElement) => {
  render(null, container);
  document.body.removeChild(container);
};

interface IProps {
  onClickClose?(): any;
  onLoginSuccess?(): any;
  onLoginFail?(error: any): any;
}

const showLoginVue = (p: IProps = {}) => {
  const container = document.createElement("div");

  const close = () => {
    remove(container);
    if (p.onClickClose && isFunc(p.onClickClose)) {
      p.onClickClose();
    }
  };

  const props = {
    onOnClickClose: close,
    onOnLoginSuccess() {
      remove(container);
      if (p.onLoginSuccess && isFunc(p.onLoginSuccess)) {
        p.onLoginSuccess();
      }
    },
    onOnLoginFail: p.onLoginFail || function () {},
  };

  // 1 创建vNode
  const vm = h(LoginPopupVue, props);
  // 2 render
  render(vm, container);
  // 3 append body
  document.body.appendChild(container);

  return {
    close,
  };
};

const toLogin = () => {
  return new Promise((resolve, reject) => {
    showLoginVue({
      onClickClose() {
        reject("用户取消");
      },
      onLoginSuccess() {
        resolve("登陆成功");
      },
      onLoginFail(error) {
        console.error(error);
        // reject("登陆失败");
      },
    });
  });
};

export const Login = {
  showLoginVue,
  toLogin,
};
