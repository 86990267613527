import { apiGetEducationList } from "@/api/dist";
import { IData_dictEducation } from "@resume/types";
import { defineStore } from "pinia";
import { computed, reactive } from "vue";

export const useDistStore = defineStore("dist", () => {
  type xueli = IData_dictEducation & { id: string };

  const state = reactive({
    education: [] as xueli[],
  });

  const init = () => {
    updateEducation().catch(console.error);
  };

  const educationList = computed(() => state.education);

  const updateEducation = async () => {
    const res = await apiGetEducationList();
    if (res.success && Array.isArray(res.data)) {
      state.education = res.data as xueli[];
    }

    return res;
  };

  return { init, updateEducation, educationList };
});
