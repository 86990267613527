import {
  IData_Edu,
  IData_Resume,
  IReqAddResume,
  IReqEditResume,
  IResponseResume,
  IResQueryCompany,
  IResResumeTitleList,
} from "@resume/types";

import { get, post } from "./h";

/**
 * 通过输入关键字 -> 查询学院信息
 */
export const apiFindEduByName = (name: string) =>
  get<IData_Edu[]>("/rd3/edu", { q: name }, { toastError: false });

/**
 * 通过输入关键字 -> 查询公司信息
 */
export const apiFindWorkByName = (name: string) =>
  get<IResQueryCompany[]>("/rd3/inc", { q: name }, { toastError: false });

/**
 * 添加简历
 */
export const apiAddResume = (val: IReqAddResume) =>
  post<string>("/resume/add", val);

/**
 *  获取用户 简历list ，数据仅包含 简历id、title
 */
export const apiGetResumeTitleList = () =>
  get<IResResumeTitleList[]>("/resume/titleList", {}, { toastError: false });

/**
 * 根据 id 获取简历信息
 *
 * edit 用
 */
export const apiGetResumeById = (resumeId: string) =>
  get<IResponseResume>(
    "/resume/getResumeById",
    { resumeId },
    { toastError: false }
  );

/**
 * 更新 简历信息
 */
export const apiUpdateResume = (val: IReqEditResume) =>
  post<string>("/resume/edit", val);

/**
 * 通过 id 获取 open 简历
 */
export const apiGetResumeByResumeId = (resumeId: string) =>
  get<IResponseResume>(`/open/resume/${resumeId}`);

/**
 * 随机获取一份 open 简历
 */
export const apiGetRandomResumeId = () =>
  get<string>(`/open/resume/random`, undefined, { toastError: false });
