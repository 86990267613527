import { createVNode, render, VNode } from "vue";
import viewTemplate from "./Toast.vue";

export interface ITostProps {
  msg?: string;
  type?: "info" | "ok" | "err";
}

interface ITostProps2 extends ITostProps {
  duration?: number;
}

const closeToast = (vm: VNode, container: HTMLDivElement) => {
  const dom = vm.el as HTMLDivElement;
  if (!dom) return;
  // const toastDom = dom.querySelector(".qins-toast");

  if (dom?.classList) {
    dom?.classList.add("q-toast-close");

    /**
     * 动画时间，必须和class 定义的一直
     */
    const animationTime = 900;

    // 移除 body
    setTimeout(() => {
      render(null, container);
      document.body.removeChild(container);
    }, animationTime);
  }
};

const Toast = (options: ITostProps2) => {
  const container = document.createElement("div");

  const props = { ...options };

  // 1 创建vNode
  const vm = createVNode(viewTemplate, props);
  // 2 render
  render(vm, container);
  // 3 append body
  document.body.appendChild(container);

  const close = () => closeToast(vm, container);
  if (props.duration) {
    setTimeout(() => {
      close();
    }, props.duration);
  }

  return { close };
};

Toast.create = (
  msg: string,
  duration: number = 3000,
  options: ITostProps2 = {}
) => Toast({ msg, duration, ...options });

Toast.info = (
  msg: string,
  duration: number = 3000,
  options: ITostProps2 = {}
) => Toast({ msg, duration, type: "info", ...options });

Toast.ok = (msg: string, duration: number = 3000, options: ITostProps2 = {}) =>
  Toast({ msg, duration, type: "ok", ...options });

Toast.error = (
  msg: string,
  duration: number = 3000,
  options: ITostProps2 = {}
) => Toast({ msg, duration, type: "err", ...options });

export { Toast as QToast };
