export interface CheckResult {
  success: boolean;
  msg?: string;
}

/**
 * 验证手机号
 * @param phone
 * @returns
 */
export function checkPhone(phone: string | number): CheckResult {
  const p = String(phone);
  if (!p) {
    return { success: false, msg: "手机号不能为空" };
  }

  if (p.length != 11) {
    return { success: false, msg: "手机号应该为11位" };
  }

  // https://juejin.cn/post/6844903886004682765#heading-32
  const check =
    /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;

  if (!check.test(p)) {
    return { success: false, msg: "请输入正确的手机格式" };
  }

  return { success: true };
}

/**
 * 验证密码
 * @param pwd
 * @returns
 */
export function checkPwd(pwd: string) {
  if (!pwd) {
    return { success: false, msg: "密码不能为空" };
  }

  if (pwd.length < 6) {
    return { success: false, msg: "密码应该大于6位" };
  }

  const check = /^.*(?=.{6,})(?=.*\d)(?=.*[A-z]).*$/;
  if (!check.test(pwd)) {
    return { success: false, msg: "密码应该大于6位 并 包含一个字母" };
  }

  return { success: true };
}

export const LoginCheck = {
  checkPhone,
  checkPwd,
};
