import { QToast } from "@/components/q/QToast";
import {
  configPlus,
  IReqConfig,
  get as _get,
  post as _post,
} from "@fff/tools/HttpClient";

import { IServerResponse } from "@resume/types";

import { Login } from "@/views/login";

const tokenKey = "1f060854-ac2a-4ab4-b3ad-2e8f07cce564";

const apiUrl = process.env.VUE_APP_API_URL;
console.log("api url => ", apiUrl);

export const saveToken = (token?: string) =>
  token && typeof token === "string" && localStorage.setItem(tokenKey, token);

export const getToken = () => localStorage.getItem(tokenKey);

const toast = (msg: string) => QToast.error(msg || "请求服务器异常");

const autoToLogin = async () => {
  console.log("todo 身份认证失败 自动登录");
  // Login.showLoginVue();
  throw new Error("身份认证失败");
};

interface IParseResError {
  canHandle: boolean;
  message: string;
  disposal: {
    func?: Function;
    data?: IServerResponse;
  };
}
/**
 * 错误解析
 */
const parseResError = (error: any) => {
  const result: IParseResError = {
    canHandle: false,
    message: "未知错误",
    disposal: {
      func: undefined as Function | undefined,
      data: undefined as IServerResponse | undefined,
    },
  };

  if (!error) {
    return result;
  }

  const { response, message } = error;
  if (!response) {
    result.message = message || "连接服务器失败，请稍后在试";
    return result;
  }

  if (response?.status === 401) {
    // 认证失败，需要登陆

    result.canHandle = true;
    result.message = "身份验证失败";
    result.disposal.func = autoToLogin;
    return result;
  }

  if (response?.data) {
    const { success, message } = response.data;

    if (!success) {
      // 逻辑错误

      result.canHandle = true;
      result.message = message;
      result.disposal.data = response.data;
      return result;
    }
  }

  return result;
};

type PreRequest = ((config: IReqConfig) => void)[];
type HandleErrors = ((ie: IParseResError) => any)[];

const getDefaultHttpClientConfig = (
  req?: PreRequest,
  err?: HandleErrors
): configPlus => ({
  baseURL: (apiUrl ? apiUrl : "") + "/api",
  // timeout: 10 * 1000, // 0 用不超时，默认配置。
  interceptors: {
    request: {
      success(config) {
        if (Array.isArray(req)) {
          for (const func of req) {
            func(config);
          }
        }
        return config;
      },
    },
    response: {
      error(error) {
        /**
         * 这里的处理中
         *
         * return 将不会报错
         *
         * throw 才会报错！
         */

        const errorResult = parseResError(error);

        if (Array.isArray(err)) {
          for (const func of err) {
            const result = func(errorResult);
            // 处理有结果，就会中断
            if (result) {
              return result;
            }
          }
        }

        return Promise.reject(error);
      },
    },
  },
});

// ------------------------------------------------------------

const addAuthorization = (config: IReqConfig) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
};
const unifiedToastError = (e: IParseResError) => {
  toast(e.message);
};

const returnError = (e: IParseResError) => {
  if (e.canHandle && e?.disposal?.data) {
    return e.disposal.data;
  }
};

interface IMyConfig {
  addToken?: boolean;
  toastError?: boolean;
}

const handleConfig = (config?: configPlus & IMyConfig): configPlus => {
  const { addToken = true, toastError = true, ...reqConfig } = config || {};

  const req_req = [];
  const handle_err = [];

  if (addToken) {
    req_req.push(addAuthorization);
  }

  if (toastError) {
    handle_err.push(unifiedToastError);
  }

  handle_err.push(returnError);

  const dfconfig = getDefaultHttpClientConfig(req_req, handle_err);

  return { ...dfconfig, ...reqConfig };
};

export const get = <T>(
  apiPath: string,
  data?: object,
  config?: configPlus & IMyConfig
) => {
  const conf = handleConfig(config);
  return _get<IServerResponse<T>>(apiPath, data, conf);
};

export const post = <T>(
  apiPath: string,
  data?: object,
  config?: configPlus & IMyConfig
) => {
  const conf = handleConfig(config);
  return _post<IServerResponse<T>>(apiPath, data, conf);
};
