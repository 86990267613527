import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/index.vue";
import { navigationGuards } from "./guards";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/edit",
    name: "edit",
    meta: {
      login: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    /* webpackChunkName: "about" */
    component: () => import("../views/edit/edit.vue"),
  },
  {
    path: "/collect",
    name: "collect",
    meta: {
      login: true,
    },
    component: () => import("../views/collect/collect.vue"),
  },
  {
    path: "/resume/:id",
    name: "resume",
    component: () => import("../views/resume/resume.vue"),
  },
  // {
  //   path: "/share/:id",
  //   name: "share",
  //   component: () => import("../views/s/s.vue"),
  // },
  // {
  //   path: "/user/:id",
  //   name: "user",
  //   component: () => import("../views/u/u.vue"),
  // },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/e404/page404.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];

const BASE_URL = "/v/";

const router = createRouter({
  // 这里不能使用 process.env.BASE_URL 原因是：
  // 部署后就可能变为了 https://resume.q123q.cc/https://cdn.q123q.cc/fed/v/resume/v/
  history: createWebHistory(BASE_URL),
  routes,
});

router.beforeEach(navigationGuards);

export default router;
