import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dcc97580"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-layout-time-name-tag"
};
const _hoisted_2 = {
  class: "edu-item"
};
const _hoisted_3 = {
  class: "info1 name"
};
const _hoisted_4 = {
  class: "info1 time"
};
const _hoisted_5 = {
  class: "info1 tag"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "name", {}, undefined, true)]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "time", {}, undefined, true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "tag", {}, undefined, true)])])]);
}