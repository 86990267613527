// 数据转换
// 因为前端 state 存的 是 string，而后端是 Date ，所以出现数据不一致的地方，就需要进行转换

export const isYear = (s?: string) =>
  s && s.length === 4 && typeof +s === "number";

export const year2timestamp = (year?: string, monthIndex: number = 8) => {
  if (year && isYear(year)) {
    return new Date(+year, monthIndex, 1).getTime();
  }

  return undefined;
};

export const timestamp2year = (timestamp?: number) => {
  if (timestamp) {
    return new Date(timestamp).getFullYear() + "";
  }
  return undefined;
};

//----------------------------------------------------------------

export const isYearMonth = (s?: string) =>
  s && s.length >= 4 && s.length <= 6 && typeof +s === "number";

export const yearMonth2timestamp = (yearMonthStr?: string) => {
  if (yearMonthStr && isYearMonth(yearMonthStr)) {
    const year = yearMonthStr.substring(0, 4);
    const month = yearMonthStr.substring(4);

    return new Date(+year, +month - 1, 1).getTime();
  }

  return undefined;
};

export const timestamp2yearMonth = (timestamp?: number) => {
  if (timestamp) {
    const d = new Date(timestamp);
    const y = d.getFullYear();
    const m = d.getMonth() + 1;

    if (m + "".length === 1) {
      return `${y}0${m}`;
    }
    return `${y}${m}`;
  }

  return undefined;
};

// ---

export const inputDate2timestamp = (inputValue?: string) => {
  if (!inputValue) return undefined;

  const dates = inputValue.split("-");
  if (!Array.isArray(dates) || !dates.length) {
    console.log("日期解析失败 > ", inputValue, " > ", dates);
    return undefined;
  }
  const [year, month = "1", day = "1"] = dates;

  return new Date(+year, +month - 1, +day).getTime();
};

export const timestamp2InputDate = (
  timestamp?: number,
  type: "ym" | "ymd" = "ym"
) => {
  if (!timestamp) return undefined;

  const d = new Date(timestamp);
  const y = d.getFullYear();
  let m = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;

  if (m.length === 1) {
    m = "0" + m;
  }

  if (day.length === 1) {
    day = "0" + day;
  }

  if (type === "ymd") {
    return `${y}-${m}-${day}`;
  }

  return `${y}-${m}`;
};
