import { defineComponent } from "vue";
export default defineComponent({
  name: "QToast",
  props: {
    msg: {
      type: String,
      default: ""
    },
    type: String
  },
  computed: {
    style2() {
      const r = ["#dd2c3f", "#ee0a24"];
      const g = ["#07c160", "#039549"];
      const b = ["#1989fa", "#1277de"];
      let use = b;
      if (this.type === "ok") {
        use = g;
      }
      if (this.type === "err") {
        use = r;
      }
      const result = {
        background: use[0],
        border: `1px solid ${use[1]}`
      };
      return result;
    }
  }
});