import { IResponseResume } from "@resume/types";
import { computed } from "vue";

import { useLoginStore } from "@/store/login";

const fill = (n: number, f: string = "*") => {
  let result = "";
  for (let i = 0; i < n; i++) {
    result += f;
  }

  return result;
};

interface IConfig {
  /**
   *  true 显示重要数据需要登陆
   *
   * false 直接显示所有数据
   */
  showDontNeedLogin: boolean;
}

export const initComputed = (state: IResponseResume, config?: IConfig) => {
  const store = useLoginStore();

  // 最高学历
  const HighestEdu = computed(() => {
    let result = "";
    if (!Array.isArray(state.edu)) return result;

    const weightList = state.edu.map(({ level }) => level?.weight || 0);
    const MaxWeight = Math.max(...weightList);

    const edu = state.edu.find(({ level }) => level?.weight === MaxWeight);

    if (!edu) return result;

    const levelName = edu?.level?.name;
    const eduName = edu?.name;

    if (levelName && eduName) {
      result = `${edu?.level?.name}（${edu.name}）`;
    } else if (levelName) {
      result = levelName;
    }

    return result;
  });

  // 年龄
  const Age = computed(() => {
    if (!state?.basicInfo?.birthday) return "";

    const b = new Date(state?.basicInfo?.birthday);
    const n = new Date();

    const ageTime = n.getTime() - b.getTime();
    const ageS = ageTime / 1000;
    const ageMin = ageS / 60; // 1min 60s
    const ageHour = ageMin / 60; // 1h 60mis
    const ageDay = ageHour / 24; // 1day 24h

    const age = Math.floor((ageDay * 10) / 365) / 10;

    return `${age}岁（ ${b.getFullYear()}年 ）`;
  });

  const WorkTime = computed(() => {
    return "";
  });

  const Phone = computed(() => {
    if (!state.basicInfo) return "";

    const { phone } = state.basicInfo;
    if (!phone) return "";

    if (config?.showDontNeedLogin) return phone;
    if (store.isAuthenticated) return phone;

    const len = Math.trunc(phone.length / 2);
    const start = Math.trunc(len / 2);

    const a = phone.substring(0, start);
    const b = fill(len, "*");
    const c = phone.substring(start + len);

    return `${a} ${b} ${c}`;
  });

  const WeChat = computed(() => {
    if (!state.basicInfo) return "";

    const { weChat } = state.basicInfo;
    if (!weChat) return "";

    if (config?.showDontNeedLogin) return weChat;
    if (store.isAuthenticated) return weChat;

    const len = Math.trunc(weChat.length / 2);
    const start = Math.trunc(len / 2);

    const a = weChat.substring(0, start);
    const b = fill(len, "*");
    const c = weChat.substring(start + len);

    return `${a} ${b} ${c}`;
  });

  const Email = computed(() => {
    if (!state.basicInfo) return "";

    const { email } = state.basicInfo;
    if (!email) return "";

    if (config?.showDontNeedLogin) return email;
    if (store.isAuthenticated) return email;

    const atIndex = email.indexOf("@");

    const emailName = email.substring(0, atIndex);
    const emailDomain = email.substring(atIndex);

    return `${fill(emailName.length)}${emailDomain}`;
  });

  return { HighestEdu, Age, WorkTime, Phone, WeChat, Email };
};
